@import '@/styles/common.scss';

/deep/.demo-tabs>.el-tabs__header {
    padding-left: 10px;
}

/deep/.demo-tabs>.el-tabs__content {
    padding: 10px;
    font-size: 32px;
}

.myDivider {
    height: 10px;
    background-color: #E9EEF3;
    width: 100%;
}

.tabStyle {
    height: 50px;
    margin-left:10px;
}
